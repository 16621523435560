import React, { FC, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

interface GiveMeAccessModalProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    myRecordedIp: string;
    setIp: (myIp: string) => void;
}

const GiveMeAccessModal : FC<GiveMeAccessModalProps> = (props: GiveMeAccessModalProps) => {
    const [myIp, setMyIp] = useState<string>(props.myRecordedIp);

    const closeModal = () => {
        props.setShowModal(false);
    };
    const onGiveMeAccess = () => {
        if (myIp !== "") {
            console.log("myIp: ");
            console.log(myIp);
            props.setIp(myIp);
        } else {
            console.log("no IP given, using recorded IP: " + props.myRecordedIp);
            setMyIp(props.myRecordedIp);
            props.setIp(props.myRecordedIp);
        }

        closeModal();
    };

    return (
        <Modal show={props.showModal}>
            <Modal.Header closeButton onClick={closeModal}>
                <Modal.Title>Give access to:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><label htmlFor="myIp">IP Address:</label> <input id="myIp" type="text" value={myIp} onChange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                    ): void => setMyIp(e.target.value)} /></p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onGiveMeAccess}>Give access</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default GiveMeAccessModal;
