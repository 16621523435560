import React, { FC, useState, useRef } from 'react';
import { format } from 'date-fns';
import CustomTimeModal from './components/CustomTimeModal';
import GiveMeAccessModal from './components/GiveMeAccessModal'
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

interface VmProps {
    vm: {
        instance_id: string;
        name: string;
        type: string;
        state: string;
        expiry: string;
    };
    refreshVMData: () => void;
    updateVMRuntime: (instanceId: string, numSeconds: string) => void;
    myRecordedIp: string;
    giveMeAccess: (instanceId: string, myIp: string) => void;
}

interface SelectOption {
    value: string,
    text: string,
}

const delay = (ms: any) => new Promise(
    resolve => setTimeout(resolve, ms)
);

const Vm : FC<VmProps> = (props: VmProps) => {
    const [showCustomTimeModal, setShowCustomTimeModal] = useState(false);
    const [showGiveMeAccessModal, setShowGiveMeAccessModal] = useState(false);
    var now = new Date();

    const stopTimeOptions : Array<SelectOption> = [
        { value: "0", text: "Stop now" },
        { value: "15", text: "Stop at 3pm" },
        { value: "16", text: "Stop at 4pm" },
        { value: "17", text: "Stop at 5pm" },
        { value: "18", text: "Stop at 6pm" },
        { value: "19", text: "Stop at 7pm" },
        { value: "", text: "Never Stop" },
        { value: "custom", text: "Custom time..." }
    ];

    const getSecondsUntil = (hours: number) => {
        var stopDate = new Date();
        stopDate.setHours(hours);
        stopDate.setMinutes(0);
        stopDate.setSeconds(0);

        var secondsUntil = (stopDate.getTime() - now.getTime());
        secondsUntil = Math.floor(secondsUntil/1000);

        return secondsUntil;
    };

    const [stopTime, setStopTime] = useState(stopTimeOptions[4].value);
    const stateRef = useRef<HTMLSpanElement | null>(null);
    const hourHasPassed = (hour: number) => {
        if (hour && hour > 0) {
            return now.getHours() >= hour;
        } else {
            return false;
        }
    };

    const getTimeDateString = (timeDate: Date) => {
        var timeDateString = "";

        // format time
        var hours = timeDate.getHours();
        var minutes = timeDate.getMinutes();
        var amPm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        timeDateString += hours ? hours : 12;
        timeDateString += ":";
        timeDateString += minutes < 10 ? '0' + minutes : minutes;
        timeDateString += amPm;

        // formate date
        var yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate()-1);
        var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        var tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate()+1);
        var dayAfterTomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate()+2);

        if (timeDate >= yesterday && timeDate < today) {
            timeDateString += " yesterday";
        } else if (timeDate >= tomorrow && timeDate < dayAfterTomorrow) {
                timeDateString += " tomorrow";
        } else if (timeDate > tomorrow || timeDate < yesterday) {
            timeDateString += " on " + format(timeDate, "dd MMM");
        }

        // return time (and date if not today)
        return timeDateString;
    };

    const onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setStopTime(e.target.value);

        if (e.target.value === "custom") {
            setShowCustomTimeModal(true);
        }
    };

    const setCustomTime = (secondsUntilStop: string) => {
        updateVmTime(secondsUntilStop);
    };

    const onUpdate = () => {
        if (stopTime === "custom") {
            setShowCustomTimeModal(true);
            return;
        }

        var secondsUntilStop = stopTime;
        var stopHour = Number(stopTime);

        if  (stopHour && stopHour > 0) {
            secondsUntilStop = "" + getSecondsUntil(stopHour);
        }

        updateVmTime(secondsUntilStop);
    };

    const updateVmTime = (secondsUntilStop: string) => {
        console.log(getTimeDateString(new Date()) + " updating VM runtime...");
        console.log("secondsUntilStop = " + secondsUntilStop);
        props.updateVMRuntime(props.vm.instance_id, secondsUntilStop);

        do {
            updateVMDisplay();
        } while (isPending(props.vm.state));
    };

    const onSetIp = (myIp: string) => {
        props.giveMeAccess(props.vm.instance_id, myIp);
    };

    const onGiveMeAccess = () => {
        setShowGiveMeAccessModal(true);
    };

    const isPending = (currentState: string) => {
        return currentState && (!currentState.includes('running') && !currentState.includes('stopped'));
    };

    async function updateVMDisplay() {
        console.log(getTimeDateString(new Date()) + " stateRef.current.innerHTML = " + (stateRef.current ? stateRef.current.innerHTML : "null"));
        console.log("waiting for 45 seconds...");
        await delay(45000);
        console.log(getTimeDateString(new Date()) + " stateRef.current.innerHTML = " + (stateRef.current ? stateRef.current.innerHTML : "null"));
        console.log(getTimeDateString(new Date()) + " updating VM display...");
        props.refreshVMData();
        console.log(getTimeDateString(new Date()) + " stateRef.current.innerHTML = " + (stateRef.current ? stateRef.current.innerHTML : "null"));
        console.log(getTimeDateString(new Date()) + " VM state update complete.");
        console.log(getTimeDateString(new Date()) + " stateRef.current.innerHTML = " + (stateRef.current ? stateRef.current.innerHTML : "null"));
    };

    return (
        <tr className={`${new Date(props.vm.expiry) < now ? "offline" : "online"}`}>
            <CustomTimeModal showModal={showCustomTimeModal} setShowModal={setShowCustomTimeModal} setCustomTime={setCustomTime} />
            <GiveMeAccessModal showModal={showGiveMeAccessModal} setShowModal={setShowGiveMeAccessModal}
                               myRecordedIp={props.myRecordedIp} setIp={onSetIp} />
            <td>{props.vm.name}
                <br/><span className="small">{props.vm.type}</span></td>
            <td>
            { isPending(props.vm.state ? props.vm.state + "" : "") &&
                <>
                    <span className="lds-dual-ring"></span>
                    <span className="text-muted" ref={stateRef}>{props.vm.state}</span>
                </>
            }
            { !isPending(props.vm.state ? props.vm.state + "" : "") &&
                <span ref={stateRef}>{props.vm.state}</span>
            }
                <br/><span className={`small ${new Date(props.vm.expiry) < now ? " text-muted" : ""}`}>
                { !props.vm.expiry &&
                    <span>no stop time</span>
                }
                { props.vm.expiry && (new Date(props.vm.expiry) < now) &&
                    <span>at&nbsp;
                    {getTimeDateString(new Date(props.vm.expiry))}</span>
                    }
                { props.vm.expiry && (new Date(props.vm.expiry) >= now) &&
                    <span>until&nbsp;
                    {getTimeDateString(new Date(props.vm.expiry))}</span>
                    }
                </span>
            </td>
            <td>
                <div className="input-group">
                    <select className="form-select"
                            value={stopTime}
                            onChange={onChangeSelect}>
                        {stopTimeOptions.map((option) => {
                            return(
                            <option key={option.value} value={option.value}
                                    disabled={hourHasPassed(Number(option.value))}>{option.text}</option>
                                )
                            })}
                    </select><button className="btn btn-primary" onClick={onUpdate}>Update</button>
                </div>
            </td>
            <td><button className="btn btn-outline-primary" onClick={onGiveMeAccess}>Give me access</button></td>
        </tr>
    )
};

export default Vm;