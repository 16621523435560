import React, { FC } from 'react';
import Vm from './Vm';

interface VmsProps {
    vmsData: never[];
    refreshVMData: () => void;
    updateVMRuntime: (instanceId: string, numSeconds: string) => void;
    myRecordedIp: string;
    giveMeAccess: (instanceId: string, myIp: string) => void;
}

const Vms : FC<VmsProps> = (props: VmsProps) => {

    const refreshData = (e: any) => {
        e.preventDefault();
        props.refreshVMData();
    }


    return (
        <div className="page-header">

            <a id="refresh" href="/" className="btn btn-primary" onClick={refreshData}>Refresh</a>
            <table className="table table-hover" id="dataTable">
                <thead>
                    <tr className="table-primary">
                        <th scope="col">Name/type</th>
                        <th scope="col">State</th>
                        <th scope="col">Update stop time</th>
                        <th scope="col">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    { props.vmsData.map(function (vm, index) {
                        return (
                            <Vm key={vm['instance_id']} vm={vm}
                                updateVMRuntime={props.updateVMRuntime}
                                refreshVMData={props.refreshVMData}
                                myRecordedIp={props.myRecordedIp}
                                giveMeAccess={props.giveMeAccess} />
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default Vms;