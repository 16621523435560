import React, { useState, useEffect } from 'react';
import './App.css';
import Vms from './Vms';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import moment from 'moment';
import axios from 'axios';

Amplify.configure(config);

function App() {
    const callApiUrl = "https://baxh9mm52a.execute-api.ap-southeast-2.amazonaws.com/dev/boxMetadata";
    const loginUrl = "https://icehouse-user-pool-client-dev.auth.ap-southeast-2.amazoncognito.com/login?client_id=2c4dnkjhfcb5g1stcgt933857v&response_type=token&scope=email+openid&redirect_uri=" + window.location.href;
    const updateRuntimeUrl = "https://baxh9mm52a.execute-api.ap-southeast-2.amazonaws.com/dev/modifyBox";
    const getIPUrl = "https://ipv4.icanhazip.com/";
    const updateFWUrl = "https://baxh9mm52a.execute-api.ap-southeast-2.amazonaws.com/dev/updateFW";
    const queryString = require('query-string');
    const parsedHash = queryString.parse(window.location.hash);
    const [vmsData, setVmsData] = useState([]);
    const [myRecordedIp, setMyRecordedIp] = useState("");
    const headers = {
        headers: {
            'Authorization': localStorage.getItem('id_token')
        }
    };

    const onLogout = (e: any) => {
        e.preventDefault();
        logout();
    };

    const logout = () => {
        localStorage.clear();
        window.location.href = './';
    };

    useEffect(() => {
        console.log("about to request IP from " + getIPUrl);
        axios.get(getIPUrl)
            .then(response => {
                console.log("IP: " + response.data);
                setMyRecordedIp(response.data);
            })
            .catch(error => {
                console.log("An error occurred:");
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (parsedHash && parsedHash.id_token) {
            var expiry = moment().add(parsedHash.expires_in, 's');

            localStorage.setItem('id_token', parsedHash.id_token);
            localStorage.setItem('expiry', expiry.toString());
            window.location.href = './';
        }

        refreshVMData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isExpired = () => {
        if (localStorage.getItem('expiry')) {
            var expiryDate = moment(localStorage.getItem('expiry'));
            console.log('checking if expiry date has passed...');

            if (expiryDate.isBefore(moment())) {
                console.log("Expiry date has passed. Logging out now.");
                logout();
                return true;
            } else {
                console.log("Expiry date has not passed, continuing...")
                return false;
            }
        }
    }

    async function refreshVMData() {
        if (!isExpired()) {
            axios.get(callApiUrl, headers)
                .then(response => setVmsData(response.data.boxes))
                .catch(error => console.log(error));
        }
    }

    async function updateVMRuntime(instanceId: string, numSeconds: string) {
        if (!isExpired()) {
            axios.get(updateRuntimeUrl, {
                    headers: {
                        'Authorization': localStorage.getItem('id_token')
                    },
                    params: {
                        instance_id: instanceId,
                        number_of_secs: numSeconds
                    }
                })
                .then(response => refreshVMData())
                .catch(error => console.log(error));
        }
    }

    async function sendIP(response: string) {
        if (!isExpired()) {
            axios.get(updateFWUrl, {
                    headers: {
                        'Authorization': localStorage.getItem('id_token')
                    },
                    params: {
                        ip_address: response
                    }
                })
                .then(response => console.log(response))
                .catch(error => console.log(error));
        }
    }

    function giveMeAccess(instanceId: string, myIp: string) {
        if (!isExpired()) {
            sendIP(myIp);
            console.log("sent IP: " + myIp);
        }
    }

    return (
        <div className="App">
            <div className="navbar navbar-expand-lg fixed-top navbar-dark bg-primary">
                <div className="container">
                    <a href="../" className="navbar-brand">Dev VMs</a>
                    <ul className="navbar-nav ms-md-auto">
                    { localStorage.getItem('id_token') != null 
                        ? <li className="nav-item"><a id="logout" href="/" className="btn btn-primary" onClick={onLogout}>Logout</a></li>
                        : <li className="nav-item"><a id="login" className="btn btn-primary" href={loginUrl}>Login</a></li>
                    }
                    </ul>
                </div>
            </div>
            { localStorage.getItem('id_token') != null &&
            <div className="container">
                <Vms vmsData={vmsData} refreshVMData={refreshVMData} myRecordedIp={myRecordedIp}
                    updateVMRuntime={updateVMRuntime} giveMeAccess={giveMeAccess} />
            </div>
            }
            { localStorage.getItem('id_token') == null &&
            <div className="container">
                <p><br/>You are not logged in.</p>
            </div>
                }
        </div>
  );
}

export default App;
