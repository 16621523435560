import React, { FC, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';

interface CustomTimeModalProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    setCustomTime: (secondsUntilStop: string) => void;
}

const CustomTimeModal : FC<CustomTimeModalProps> = (props: CustomTimeModalProps) => {
    const [dateTime, setDateTime] = useState<Date | null>(new Date());
    const now = new Date();

    const closeModal = () => {
        props.setShowModal(false);
    };
    const updateVM = () => {
        if (dateTime) {
            console.log("date and time chosen: ");
            console.log(dateTime);

            var secondsUntil = (dateTime.getTime() - now.getTime());
            secondsUntil = Math.floor(secondsUntil/1000);

            props.setCustomTime("" + secondsUntil);

            closeModal();
        } else {
            console.log("no date/time chosen");
        }
    };

    return (
        <Modal show={props.showModal}>
            <Modal.Header closeButton onClick={closeModal}>
                <Modal.Title>Set Custom Time</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DateTimePicker onChange={setDateTime} value={dateTime} format="dd/MM/y h:mm:ss a" />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={updateVM}>Update VM</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomTimeModal;
